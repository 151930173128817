import * as React from "react";
import styled from "styled-components";
import modalBackground from "../../assets/images/splendeur-conference/hero-splendeur-bg.webp";
import splendeurLogo from "../../assets/images/splendeur-conference/logo.png";

const ContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 515px) {
    flex-direction: column;
    gap: 1rem;
    #join {
      margin-bottom: 0.5rem;
    }
  }
`;

function VstcConferencHeaderComponent() {
  return (
    <div style={{ backgroundImage: `url(${modalBackground})` }}>
      <section className="section section-center" style={{ padding: "0.5rem" }}>
        <ContainerDiv>
          <div style={{ color: "#77161b", textTransform: "uppercase", fontWeight: "bold" }}>La conférence</div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://lasplendeuraulieudescendres.com/"}
              aria-label="lien du site conférence splendeur au lieu des cendres"
            >
              <img width={180} src={splendeurLogo} alt="logo conférence splendeur au lieu des cendres" />
            </a>
          </div>
          <div id="join">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="btn center-btn"
              href="https://square.link/u/4zCFwKMC"
              style={{ display: "inline", textTransform: "uppercase" }}
            >
              Inscris toi
            </a>
          </div>
        </ContainerDiv>
      </section>
    </div>
  );
}

export default VstcConferencHeaderComponent;
