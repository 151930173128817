import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

function VstcConferenceComponent() {
  return (
    <div>
      <StaticImage
        src="../../assets/images/splendeur-conference/affiche-splendeur-au-lieu-des-cendres-edition-2024.webp"
        alt="Conférence la Splendeur au lieu des cendres"
        placeholder="blurred"
      />
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="btn center-btn"
        style={{ marginBottom: " 3rem", textTransform: "uppercase" }}
        href="https://square.link/u/4zCFwKMC"
      >
        Inscris toi
      </a>
    </div>
  );
}

export default VstcConferenceComponent;
